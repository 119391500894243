import React, { useState } from "react";
import FormError from "../Alert/FormError";
import CloseIcon from "../../assets/icons/closewhitebg.svg"

const CancelModal = ({ isOpen, onClose, onSubmit, isCancelled }) => {
    const [reason, setReason] = useState("");
    const [error, setError] = useState(null)

    if (!isOpen) return null;

    const handleSubmit = () => {
        if (!reason?.trim()) {
            setError("Reason cannot be empty")
        } else {
            onSubmit(reason);
            onClose();
        }

    };

    return (
        <div className="fixed inset-0 flex items-start justify-center bg-black bg-opacity-90 z-50 modal_dialog">
            <div className="bg-white w-96 mt-10 modal_content">
                <div className="flex justify-between items-center p-4 mb-4 modal_header">
                    <h2 className="head">Reason for Cancellation</h2>
                    <button onClick={onClose}>
                        {/* &#10005; */}
                        <img src={CloseIcon} alt="close" width="24px" height="24px" />
                    </button>
                </div>
                <div className="flex flex-col gap-2 modal_body p-4">
                    {isCancelled ? <span>{reason}</span> : <textarea
                        className="w-full h-24 p-2 textarea"
                        placeholder="Write your text here..."
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        disabled={isCancelled}
                    />}
                    <FormError error={error} />
                </div>
                {!isCancelled && <div className="modal_footer flex justify-end p-4">
                    <button
                        onClick={handleSubmit}
                        className="mt-4 px-4 py-2 w-auto"
                    >
                        Submit
                    </button>
                </div>}
            </div>
        </div>
    );
};

export default CancelModal;
