import React, { useEffect, useRef, useState } from 'react';
// import FormError from "../Alert/FormError";
import { Map, Marker } from "@vis.gl/react-google-maps";
import "../../styles/postlogin/services.css"
import FormError from "../Alert/FormError";

// Modal Component for adding a new address
const AddAddressModal = ({ show, setShow, onClickFn, onChangeFn, formErrors, userLocation, addressValues, setAddressValues }) => {
    // const [markerLocation, setMarkerLocation] = useState({
    //     lat: userLocation?.lat, lng: userLocation?.lon
    // });

    const dropdownRefAddressType = useRef(null);

    const [isAddressTypeVisible, setAddressTypeVisible] = useState(false);
    const [addressTypeOption, setAddressTypeOption] = useState(null);

    const handleAddressTypeClick = (option) => {
        setAddressValues({ ...addressValues, "type": option });
        setAddressTypeOption(option);
        setAddressTypeVisible(false);
    };

    // function to handle clicking outside of the input and dropdown
    const handleClickOutside = (event) => {
        if (dropdownRefAddressType.current && !dropdownRefAddressType.current.contains(event.target)) {
            setAddressTypeVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleAddressTypeSelect = () => {
        setAddressTypeVisible(true)
    }


    const markerLocation = { lat: userLocation?.lat, lng: userLocation?.lon }

    return (
        <div className={`fixed overflow-scroll inset-0 md:px-20 flex items-center justify-center bg-black bg-opacity-90 ${show ? 'block' : 'hidden'}`}>
            <div className="bg-white p-4 rounded-lg shadow-lg w-full">

                <section className="w-full bg-white rounded-lg p-6 pb-4 pick_location">
                    <div className="flex justify-between">
                        <div>
                            <span className="first_head">Add </span><span className="second_head">New Address</span>
                        </div>
                    </div>
                    <div className="mt-4 pick_location_body">
                        <div className="w-full rounded-lg shadow-sm h-96">
                            <Map
                                style={{ borderRadius: "20px" }}
                                defaultZoom={13}
                                defaultCenter={markerLocation}
                                gestureHandling={"greedy"}
                                disableDefaultUI
                            >
                                <Marker position={markerLocation} />
                            </Map>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 pick_location_fields">
                            <div className="relative">
                                <input
                                    type="text"
                                    defaultValue=""
                                    placeholder=" "
                                    className={`input_field mt-1 block w-full ${formErrors?.block
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    name="block"
                                    onChange={(e) => {
                                        onChangeFn(e);
                                    }}
                                />
                                <span className="input_placeholder">Apartment/Road/Area</span>
                                <FormError error={formErrors.block} />
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    defaultValue=""
                                    placeholder=" "
                                    className={`input_field mt-1 block w-full ${formErrors?.house_number
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    name="house_number"
                                    onChange={(e) => {
                                        onChangeFn(e);
                                    }}
                                />
                                <span className="input_placeholder">House/Flat No</span>
                                <FormError error={formErrors.house_number} />
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    defaultValue=""
                                    placeholder=" "
                                    className={`input_field mt-1 block w-full ${formErrors?.location_name
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    name="location_name"
                                    onChange={(e) => {
                                        onChangeFn(e);
                                    }}
                                />
                                <span className="input_placeholder">Location{"'"}s Name</span>
                                <FormError error={formErrors.location_name} />
                            </div>
                            <div className="relative w-full" ref={dropdownRefAddressType}>
                                <input
                                    type="text"
                                    className={`input_field mt-1 block w-full select_quantity ${formErrors?.type
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    placeholder=" "
                                    name="type"
                                    value={addressTypeOption}
                                    onFocus={handleAddressTypeSelect}
                                />
                                <span className="input_placeholder">Address Type</span>
                                <FormError error={formErrors.type} />
                                {isAddressTypeVisible && (
                                    <div className="absolute left-0 right-0 z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                                        <ul className="py-2">
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Home")}
                                            >
                                                {"Home"}
                                            </li>
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Work")}
                                            >
                                                {"Work"}
                                            </li>
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Friends and family")}
                                            >
                                                {"Friends and family"}
                                            </li>
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Other")}
                                            >
                                                {"Other"}
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                {/* Action Buttons */}
                <div className="flex flex-col md:flex-row justify-between p-6 pt-0">
                    <div className="flex gap-2 items-center w-full">
                        <input type="checkbox" name="" value="" />
                        <span className="subtext">Save address for smooth process</span>
                    </div>
                    <div className="w-full lg:w-4/12 flex gap-3 py-6 justify-end service_buttons">
                        <button className="btn_secondary cancel" onClick={() => setShow(!show)}>Cancel</button>
                        <button className="btn_primary next" onClick={onClickFn}>Confirm Address</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAddressModal