import { createSlice } from "@reduxjs/toolkit";

const USER_INITIAL_STATE = {
    userDetails: null,
}

const UserSlice = createSlice({
    name: "userDetails",
    initialState: USER_INITIAL_STATE,
    reducers: {
        setUserDetails: (state, action) => {
            state.userDetails = action.payload
        }
    }
})

export const { setUserDetails } = UserSlice.actions;
export default UserSlice.reducer;