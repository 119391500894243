/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import { postRequest } from "../../interceptor/AxiosConfig";
import { useEffect, useRef, useState } from "react";
import ErrorMessageHandling from "../../utilsfunction/ErrorHandling";
import FormError from "../../components/Alert/FormError";
import Error from "../../components/Alert/Error";
import Success from "../../components/Alert/Success";
import { isValidOtpDigit } from "../../utilsfunction/RegexFunc";
// import { LocalStorage } from "../../utilsfunction/Encrypt";
// import axios from "axios";
import * as routePath from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import BackIcon from "../../assets/prelogin/back_arrow.svg";
import { resendOTPAPIEndpoint } from "../../apis/prelogin/PreloginApis";
import { setMessage } from "../../components/Reducers/MessageReducer";
import CompanyLogo from "../../assets/dospeedo.svg";
import VectorGrp from "../../assets/prelogin/register_graphic.png";

/* eslint-disable jsx-a11y/anchor-is-valid */
const OtpVerify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const localstorage = new LocalStorage();

  let otpApiUrl = location?.state?.otpApiUrl;
  // let email = location?.state?.email;
  let country_code = location?.state?.country_code;
  let phone_number = location?.state?.phone_number;
  let isFp = location?.state?.isFp;

  const [otpValue, setOtpValue] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);

  const otpBoxReference = useRef([]);

  const { message } = useSelector((state) => state?.messageReducer);

  const dispatch = useDispatch();

  // api call for verifying otp
  const verifyOtp = async () => {
    let payload = {};
    if (!isFp) {
      payload = {
        country_code: country_code,
        phone_number: phone_number,
        otp: otpValue.join(""),
      };
    }
    if (isFp) {
      payload = {
        country_code: country_code,
        phone_number: phone_number,
        otp: otpValue.join(""),
      };
    }
    await postRequest(otpApiUrl, payload)
      .then((res) => {
        if (res.status === 200 && !isFp) {
          // localstorage.setItem("accessToken", res.data.access_token);
          // localstorage.setItem("1", true);
          // // // set access token to global header
          // axios.defaults.headers.common[
          //   "Authorization"
          // ] = `Bearer ${res.data.access_token}`;
          navigate(
            `/${routePath.preloginPath}/${routePath.RegistrationSuccessPath}`,
            {
              replace: true,
            }
          );
        } else if (res.status === 200 && isFp) {
          navigate(
            `/${routePath.preloginPath}/${routePath.PasswordResetSuccessPath}`,
            {
              replace: true,
            }
          );
        }
      })
      .catch((error) => {
        setMessage(false);
        setErrorMessage(ErrorMessageHandling(error));
      });
  };

  function handleChange(value, index) {
    let newOtpArr = [...otpValue];
    newOtpArr[index] = value;
    setOtpValue(newOtpArr);

    if (value && index < 6 - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" || (e.target.value && index < 6 - 1)) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  useEffect(() => {
    if (otpValue.join("") !== "" && otpValue.join("")?.length !== 6) {
      setOtpError("❌ Please enter 6 digit OTP!");
    } else {
      setOtpError(null);
    }
  }, [otpValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidOtpDigit(otpValue.join(""))) {
      setOtpError("❌ Please enter 6 digit OTP no characters!");
    } else if (
      isValidOtpDigit(otpValue.join("")) &&
      otpValue.join("") !== "" &&
      otpValue.join("")?.length === 6
    ) {
      verifyOtp();
    }
  };

  // api call for resend otp
  const resendOtp = async () => {
    await postRequest(`${resendOTPAPIEndpoint}`,
      {
        country_code: country_code,
        phone_number: phone_number,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(setMessage(res.data.message));
        }
      })
      .catch((error) => {
        setErrorMessage(ErrorMessageHandling(error));
      });
  };

  return (
    <section className="container mx-auto">
      <div className="relative grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 overflow-hidden py-24 registerOuter">
        <div className="registerInner">
          <div className="registerContent text-white">
            <div class="flex justify-center items-center mb-12">
              <img src={CompanyLogo} alt="Company Logo" />
            </div>
            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Mission
              </h2>
              <p>
                <strong>
                  “Simplifying cleaning and empowering lives with a click”
                </strong>
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Vision
              </h2>
              <p>
                Building a community where cleaning professionals are valued and
                empowered to make a difference by providing solution to cleaning
                challenges
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-10">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Services
              </h2>
              <ul>
                <li>Brooming and Mopping</li>
                <li>Residential Cleaning</li>
                <li>Utensils Cleaning</li>
                <li>Commercial Cleaning</li>
                <li>Kitchen Cleaning </li>
                <li>Full Home Cleaning</li>
                <li>Bathroom Cleaning</li>
                <li>Special Occasion Service</li>
              </ul>
            </div>
          </div>
          <div className="registerGrapic">
            <img src={VectorGrp} alt="Banner Graphic Img" />
          </div>
        </div>

        <div className="registerFormWrap">
          <div className="flex flex-col gap-0">
            <div className="mx-auto w-full login_heading">
              <div className="prelogin_heading_first flex justify-content-center align-items-center">
                <span>
                  <img
                    onClick={() => {
                      navigate(-1);
                    }}
                    src={BackIcon}
                    alt="Back"
                    className=" cursor-pointer"
                  />
                </span>
                <span className="">OTP Verification</span>
              </div>
              <div className="formDescription">
                <p>Verify your mobile number with a one-time password sent to your mobile number</p>
              </div>
            </div>
            <div className="registration_form_box otpBox w-full mt-4 mb-4 mx-auto bg-white border border-gray-200 shadow-sm dark:bg-neutral-900 dark:border-neutral-700">
              <div className="p-4 pt-14">
                <div className="mt-0">
                  <form>
                    <div className="flex flex-col space-y-4">
                      <div className="flex flex-row gap-2 items-center justify-between mx-auto w-full">
                        {otpValue?.map((digit, index) => (
                          <div className="w-16 h-16 opt_field" key={index}>
                            <input
                              className={`${otpValue[index] ? "otp_shadow" : ""
                                } w-full h-full flex flex-col items-center justify-center text-center px-1 outline-none rounded-md border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700`}
                              type="text"
                              key={index}
                              value={digit}
                              maxLength={1}
                              onChange={(e) =>
                                handleChange(e.target.value, index)
                              }
                              onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                              ref={(reference) =>
                                (otpBoxReference.current[index] = reference)
                              }
                              disabled={index > 0 && otpValue[index - 1] === ""}
                            />
                          </div>
                        ))}
                      </div>

                      <FormError error={otpError} />

                      <div className="flex flex-col space-y-5">
                        <div className="flex flex-row items-center justify-center underline text-center text-sm font-medium space-x-1 text-gray-500">
                          <p>Didn&apos;t get an SMS?</p>{" "}
                          <a
                            className="flex flex-row cursor-pointer items-center text_color"
                            onClick={resendOtp}
                          >
                            Send Again
                          </a>
                        </div>
                        <div>
                          <button
                            disabled={otpError || otpValue.join("").length !== 6}
                            onClick={handleSubmit}
                            className={`${otpError || otpValue.join("").length !== 6
                              ? "disabled_button flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 border-none text-white text-sm shadow-sm"
                              : "bg_button_color flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 border-none text-white text-sm shadow-sm"
                              }`}
                          >
                            Verify
                          </button>
                        </div>

                        {errorMessage && <Error errorMessage={errorMessage} />}
                        {message && <Success successMessage={message} />}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtpVerify;
