import React, { useState } from "react";
import { postRequest } from "../../../../interceptor/AxiosConfig";
import * as apiEndpoint from "../../../../apis/postlogin/PostloginApis"
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessageHandling from "../../../../utilsfunction/ErrorHandling";
import Error from "../../../../components/Alert/Error";
import RatingStarGold from "../../../../assets/rating_startyellow.svg"
import RatingStarGray from "../../../../assets/rating_stargray.svg"

const Review = () => {
    const navigate = useNavigate()
    const { id: job_id } = useParams()
    const [rating, setRating] = useState(1);
    const [comment, setComment] = useState("");

    const [errorMessage, setErrorMessage] = useState(null);

    const handleRating = (value) => {
        setRating(value);
    };

    const handleComment = (value) => {
        setComment(value);
    }

    // submitting a review
    const handleSubmit = async () => {
        // console.log("Rating:", rating);
        // console.log("Comment:", comment);
        let payload = { job_id: job_id, rating: rating, comment: comment }
        await postRequest(`${apiEndpoint.bookingReviewAPIEndpoint}`, payload)
            .then((response) => {
                if (response.status === 201) {
                    navigate(-1)
                }
            }).catch((error) => {
                setErrorMessage(ErrorMessageHandling(error))
            })
    };

    return (
        <div className="container py-4">
            <div className="px-4 ps-0 breadcrump">
                <div className="flex gap-2">
                    <span className="heading_first"> My Bookings</span>

                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.98027 6.9999C7.98027 7.25081 7.88447 7.50168 7.69327 7.69297L1.67362 13.7126C1.29069 14.0955 0.669847 14.0955 0.287077 13.7126C-0.0956924 13.3298 -0.0956924 12.7091 0.287077 12.3261L5.6136 6.9999L0.287263 1.67366C-0.0955064 1.29073 -0.0955064 0.670073 0.287263 0.287333C0.670033 -0.0957766 1.29088 -0.0957766 1.6738 0.287333L7.69346 6.30683C7.88469 6.49822 7.98027 6.74909 7.98027 6.9999Z" fill="#1B9075" />
                    </svg>

                    <span className="heading_second">Review</span>
                </div>
            </div>
            {errorMessage && <Error errorMessage={errorMessage} />}
            <div className="w-full mx-auto mt-8 bg-white review">

                <div className="p-0 bgReviewProfile"></div>
                <div className="reviewProfilePic">
                    <img
                        className="rounded-full"
                        src="https://via.placeholder.com/160"
                        alt=""
                    />
                </div>
                <div className="flex flex-col justify-between items-center mt-24 px-6">
                    <h2 className="text_first">What do you think about?</h2>
                    <h2 className="text_second">Janet's work</h2>
                </div>

                <div className="flex gap-4 justify-center px-6 rating py-3 mt-2 mx-auto">
                    {[...Array(5)].map((_, i) => (
                        <button
                            key={i}
                            onClick={() => handleRating(i + 1)}
                        >
                            {i < rating ? <img src={RatingStarGold} alt="" /> : <img src={RatingStarGray} alt="" />}
                        </button>
                    ))}
                </div>

                <div className="p-6 pb-4">
                    <textarea
                        className="w-full textarea px-3 h-28"
                        placeholder="Leave your comment here..."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                    <div className="flex space-x-3 -mt-1 mb-4 p-6 bg-gray-100 predefinedReview">
                        <button className="btn_light" onClick={() => handleComment("Careful and on time")}>Careful and on time</button>
                        <button className="btn_light" onClick={() => handleComment("Good job!")}>Good job!</button>
                        <button className="btn_light" onClick={() => handleComment("Very clean!")}>Very clean!</button>
                    </div>
                </div>
            </div>
            <div className="flex justify-end w-full p-6">
                <div className="flex gap-2 w-3/12">
                    <button
                        onClick={() => navigate(-1)}
                        className="btn_secondary"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="btn_primary"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Review;
