import React from 'react';

const ProgressBar = ({ step1, step2, step3 }) => {
    return (
        <div>
            {step1 && !step2 && !step3 && <div className="w-full bg-gray-500 pheight"></div>}
            {step2 && <div className={`w-6/12 pheight ${step2 ? "activeP" : ""} flex items-center`}></div>}
            {step3 && <div className={`w-full pheight ${step3 ? "activeP" : ""} flex items-center`}></div>}
            <div className="relative ptop flex items-center justify-between w-full">
                <div className={`w-4 h-4 ${step1 ? "bgStep" : "bg-gray-500"} rounded-full`}></div>
                <div className={`w-4 h-4 ${step2 ? "bgStep" : "bg-gray-500"} rounded-full`}></div>
                <div className={`w-4 h-4 ${step3 ? "bgStep" : "bg-gray-500"} rounded-full`}></div>
            </div>
        </div>
    );
};

export default ProgressBar;

