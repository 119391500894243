/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import * as routePath from "../../routes";
import ErrorMessageHandling from "../../utilsfunction/ErrorHandling";
import { isValidPhoneNumber } from "../../utilsfunction/RegexFunc";
import FormError from "../../components/Alert/FormError";
import { useEffect, useState } from "react";
import { postRequest } from "../../interceptor/AxiosConfig";
import {
  forgotPasswordAPIEndpoint,
  verifyFPAPIEndpoint,
} from "../../apis/prelogin/PreloginApis";
import Error from "../../components/Alert/Error";
import { useDispatch } from "react-redux";
import { setMessage } from "../../components/Reducers/MessageReducer";
import CountryCodeAndFlag from "../../utilsfunction/CountryCodeAndFlag";
import BackIcon from "../../assets/prelogin/back_arrow.svg";
import CompanyLogo from "../../assets/dospeedo.svg";
import VectorGrp from "../../assets/prelogin/register_graphic.png";

const ForgotPassword = () => {
  let countryCodeWithFlag = CountryCodeAndFlag();
  const { dial_code, flag } = countryCodeWithFlag[102];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialState = {
    country_code: "+91",
    phone_number: "",
  };

  const [formValues, setFormValues] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  // api call for forgot password
  const forgotPassword = async () => {
    await postRequest(`${forgotPasswordAPIEndpoint}`, {
      ...formValues,
    })
      .then((res) => {
        if (res.status === 200) {
          setErrorMessage("");
          dispatch(setMessage(res.data.message));
          navigate(
            `/${routePath.preloginPath}/${routePath.OTPVerificationPath}`,
            {
              state: {
                otpApiUrl: `${verifyFPAPIEndpoint}`,
                country_code: formValues?.country_code,
                phone_number: formValues?.phone_number,
                isFp: true,
              },
            }
          );
        }
      })
      .catch((err) => {
        setErrorMessage(ErrorMessageHandling(err));
      });
  };

  //  setting the fields values
  const onHandleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // validating the fields
  const validate = (values) => {
    const errors = {};
    // if (!values.email) {
    //   errors.email = "Email Id is required!";
    // } else if (!isValidEmail(values.email)) {
    //   errors.email = "Email Id is not valid!";
    // }
    if (!values.phone_number.trim()) {
      errors.phone_number = "Mobile number is required!";
    } else if (!isValidPhoneNumber(values.phone_number)) {
      errors.phone_number =
        "Mobile number is not valid, it should be 10 digits with no characters!";
    }
    return errors;
  };

  // function for handling create button event
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    // if there is no errors login() function will be called
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      forgotPassword();
    }
  }, [formErrors, isSubmit]);

  // const [countryCode, setCountryCode] = useState(null);
  // const [countryFlag, setCountryFlag] = useState(null);

  // const handleMobileCode = (arg) => {
  //   setCountryCode(arg.dial_code);
  //   setCountryFlag(arg.flag);
  //   setFormValues({ ...formValues, ["country_code"]: arg.dial_code });
  //   document.getElementById("dropdown_phone").classList.add("hidden");
  // };

  // const [showCountryCodeDrop, setShowCountryCodeDrop] = useState(false);

  // const translateFunction = () => {
  //   setShowCountryCodeDrop(!showCountryCodeDrop);
  // };

  // const dropdownRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setShowCountryCodeDrop(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <section className="container mx-auto">
      <div className="relative grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 overflow-hidden py-24 registerOuter">
        <div className="registerInner">
          <div className="registerContent text-white">
            <div class="flex justify-center items-center mb-12">
              <img src={CompanyLogo} alt="Company Logo" />
            </div>
            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Mission
              </h2>
              <p>
                <strong>
                  “Simplifying cleaning and empowering lives with a click”
                </strong>
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Vision
              </h2>
              <p>
                Building a community where cleaning professionals are valued and
                empowered to make a difference by providing solution to cleaning
                challenges
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-10">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Services
              </h2>
              <ul>
                <li>Brooming and Mopping</li>
                <li>Residential Cleaning</li>
                <li>Utensils Cleaning</li>
                <li>Commercial Cleaning</li>
                <li>Kitchen Cleaning </li>
                <li>Full Home Cleaning</li>
                <li>Bathroom Cleaning</li>
                <li>Special Occasion Service</li>
              </ul>
            </div>
          </div>
          <div className="registerGrapic">
            <img src={VectorGrp} alt="Banner Graphic Img" />
          </div>
        </div>

        <div className="registerFormWrap">
          <div className="flex flex-col gap-0">
            <div className="mx-auto w-full login_heading">
              <div className="prelogin_heading_first flex justify-content-center align-items-center">
                <span>
                  <img
                    onClick={() => {
                      navigate(-1);
                    }}
                    src={BackIcon}
                    alt="Back"
                    className=" cursor-pointer"
                  />
                </span>
                <span className="">Forgot Password</span>
              </div>
              <div className="formDescription">
                <p>
                  Enter your registered mobile number below to change your
                  password.
                </p>
              </div>
            </div>
            <div className="registration_form_box forgotPasswordBox w-full mt-4 mb-4 mx-auto bg-white border border-gray-200 shadow-sm dark:bg-neutral-900 dark:border-neutral-700">
              <div className="p-4 pt-14">
                <div className="mt-0">
                  <form>
                    <div className="grid gap-y-4">
                      <div className="relative">
                        <label htmlFor="">Mobile Number</label>
                        <div className="flex items-center">
                          <button
                            id="dropdown-phone-button"
                            data-dropdown-toggle="dropdown-phone"
                            className={`py-3 px-4 flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-white-100 border-r-0 border ${formErrors?.phone_number
                              ? `form_error_border_code`
                              : `border-gray-200`
                              } rounded-s-lg `}
                            type="button"
                            // onClick={() => translateFunction()}
                            disabled
                          >
                            {/* {countryFlag}&nbsp;{countryCode} */}
                            {flag}&nbsp;{dial_code}
                            <svg
                              className="w-2.5 h-2.5 ms-2.5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                          </button>
                          {/* {showCountryCodeDrop ? (
                      <div
                        id="dropdown_phone"
                        ref={dropdownRef}
                        className="z-10 mobile_code bg-white divide-y divide-gray-100 rounded-lg shadow w-100 dark:bg-gray-700"
                      >
                        <ul
                          className="mobile_code_list py-2 text-sm text-gray-700 dark:text-gray-200"
                          aria-labelledby="dropdown-phone-button"
                        >
                          {countryCodeWithFlag?.map((data) => {
                            return (
                              <li
                                className={`${
                                  countryCode === data.dial_code ? `active` : ``
                                }`}
                              >
                                <button
                                  type="button"
                                  className="inline-flex w-full px-4 py-2 text-sm text-gray-700  dark:text-gray-200"
                                  role="menuitem"
                                  onClick={(e) => {
                                    handleMobileCode(data);
                                  }}
                                >
                                  <span className="inline-flex items-center">
                                    {data.flag}&nbsp;
                                    {data.name}&nbsp;{data.dial_code}
                                  </span>
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )} */}
                          <div className="relative w-full">
                            <input
                              type="text"
                              id="phone_number"
                              name="phone_number"
                              className={`block p-2.5 z-20 py-3 px-4 block w-full text-sm ${formErrors?.phone_number
                                ? `form_error_border_input`
                                : `border-gray-200`
                                } rounded-e-lg border-s-0 border`}
                              required
                              placeholder="Mobile Number"
                              aria-describedby="phone-error"
                              onChange={(e) => {
                                onHandleChange(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {formErrors.phone_number ? (
                        <FormError error={formErrors.phone_number} />
                      ) : //  formErrors.country_code ? (
                        //   <FormError error={formErrors.country_code} />
                        // ) :
                        null}
                      <button
                        type="submit"
                        className="bg_button_color w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white disabled:opacity-50 disabled:pointer-events-none"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Send OTP
                      </button>

                      {errorMessage ? (
                        <Error errorMessage={errorMessage} />
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
