import React, { useEffect, useState } from 'react';
import CrownIcon from "../../../../assets/icons/crownIcon.svg"
// import Tick from "../../../../assets/icons/tick.svg"
import { getRequest, postRequest } from "../../../../interceptor/AxiosConfig"
import * as apiEndpoint from "../../../../apis/postlogin/PostloginApis"
import ErrorMessageHandling from "../../../../utilsfunction/ErrorHandling"

import Error from "../../../../components/Alert/Error"

import { useSelector } from "react-redux";
import Logo from "../../../../assets/dospeedo.svg"

const SubscriptionPage = () => {
    const [subscriptionDataPremium, setSubscriptionDetailsPremium] = useState(null);
    const [subscriptionDataBasic, setSubscriptionDetailsBasic] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);

    const [currentMemberShip, setCurrentMemberShip] = useState(null);

    // getting the user details from reducer
    const { userDetails } = useSelector((state) => state?.userReducer);

    // api call for subscriptions details

    const getSubscriptionDetails = async () => {
        await getRequest(`${apiEndpoint.membershipAllPlansApiEndpoint}`)
            .then((res) => {
                if (res.status === 200) {
                    let premiumPlan = res.data.data.filter((res) => {
                        return res.name === "Premium Plan"
                    })
                    setSubscriptionDetailsPremium(premiumPlan)
                    let basicPlan = res.data.data.filter((res) => {
                        return res.name === "Basic Plan"
                    })
                    setSubscriptionDetailsBasic(basicPlan)
                }
            }).catch((err) => {
                setErrorMessage(ErrorMessageHandling(err))
            })
    }

    // const userAgent = navigator.userAgent.toLowerCase();

    // const handleSubscribeBtn = () => {
    //     if (userAgent.indexOf("safari") > -1 && userAgent.indexOf("chrome") === -1) {
    //         window.open("https://www.apple.com/in/app-store/", "_blank")
    //     } else {
    //         window.open("https://play.google.com/store/apps/details?id=com.dospeedo", "_blank")
    //     }
    // }

    // getting the membership
    const getMembershipPlan = async () => {
        await getRequest(`${apiEndpoint.subscriptionDetailsApiEndpoint}`)
            .then((res) => {
                if (res.status === 200) {
                    setCurrentMemberShip(res.data.data)
                }
            }).catch((err) => {
                setErrorMessage(ErrorMessageHandling(err))
            })
    }

    useEffect(() => {
        getSubscriptionDetails();
        getMembershipPlan()
    }, [])


    // buying the membership plan
    const buyMembershipPlan = async (data) => {
        await postRequest(`${apiEndpoint.buyMembershipPlanEndpoint}`, data)
            .then((res) => {
                if (res.status === 200) {
                    getMembershipPlan()
                }
            }).catch((err) => {
                setErrorMessage(ErrorMessageHandling(err))
            })
    }

    // payment gateway integration with razorpay and to load external script
    function loadScript(src) {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                reject(new Error(`Failed to load script: ${src}`));
            };
            document.body.appendChild(script);
        });
    }

    // function to display razorpay payment gateway
    async function displayRazorpay(id, price) {
        try {
            // load the Razorpay checkout script
            const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                setErrorMessage("Razorpay SDK failed to load");
                return;
            }

            // payment options
            const options = {
                key: process.env.REACT_APP_RAZOR_PAY_KEY, // razorpay Key ID
                amount: price * 100, //final price
                currency: "INR",
                name: "DoSpeedo",
                description: "Testing Transaction",
                image: Logo,
                // order_id: order_id,
                handler: async function (response) {
                    try {
                        // data to be sent to the server
                        const data = {
                            membership_type_id: id,
                            payment_method: "razorpay",
                            payment_status: response.razorpay_status ? response.razorpay_status : "success",
                            payment_transaction_id: response.razorpay_payment_id,
                            payment_transaction_response: response,
                        };

                        // send the payment data to your server
                        await buyMembershipPlan(data); // ensure this is awaited

                    } catch (error) {
                        setErrorMessage(`Payment handler error: ${error.message}`);
                    }
                },
                prefill: {
                    name: userDetails?.full_name,
                    email: userDetails?.email,
                    contact: userDetails?.phone_number,
                },
                notes: {
                    address: "DoSpeedo Corporate Office",
                },
                theme: {
                    color: "#1b9075",
                }
            };

            // initialize the Razorpay payment
            const paymentObject = new window.Razorpay(options);

            // handle payment failure
            paymentObject.on('payment.failed', function (response) {
                setErrorMessage(`Payment failed: ${response.error.description}`);
            });

            // open the Razorpay checkout window
            paymentObject.open();
        } catch (error) {
            setErrorMessage(`Error in payment process: ${error.message}`);
        }
    }

    return (
        <div>
            {errorMessage && <Error errorMessage={errorMessage} />}
            <div className="container mx-auto relative bg-gray-100 mt-4 min-h-screen">
                <h1 className="big_text mb-8 md:mb-16 text-center">
                    BECOME DOSPEEDO FAMILY MEMBER
                </h1>
                <div className="relative flex flex-col lg:flex-row items-center justify-center space-y-6 lg:space-y-0 lg:space-x-6">
                    {/* Basic Plan */}
                    <div className={`basic_subscription bg-white w-full sm:w-3/4 lg:w-1/3 ${currentMemberShip?.membershipType?.name === "Basic Plan" ? "z-40 subsShadow boxHeight" : ""}`}>
                        <div className="header">
                            <h2 className="text-xl font-semibold text-center text-white">{subscriptionDataBasic ? subscriptionDataBasic[0]?.name : null}</h2>
                            <p className="text-4xl md:text-5xl font-bold my-4 text-center text-white">{"Rs "}{subscriptionDataBasic ? subscriptionDataBasic[0]?.price : null}</p>
                            <p className="mb-4 text-center text-white">{subscriptionDataBasic ? subscriptionDataBasic[0]?.duration : null}{" "}{subscriptionDataBasic ? subscriptionDataBasic[0]?.duration_type : null}</p>
                        </div>
                        {currentMemberShip?.membershipType?.name === "Basic Plan" && <div className="crownpositionBasic">
                            <span className="text-green-700 text-2xl">
                                <img src={CrownIcon} alt="" />
                            </span>
                        </div>}
                        <div className="p-6 pb-0 pt-4">
                            <div className="benefits text-gray-600 space-y-3 mb-4" dangerouslySetInnerHTML={{ __html: subscriptionDataBasic ? subscriptionDataBasic[0]?.benefits : "" }}>
                                {/* <div className="flex gap-4 items-center">
                                    <img src={Tick} alt="" />
                                    <span className="subtext">Express service delivery-Cleaning professional will arive within 45 mins after confirmation of order</span>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <img src={Tick} alt="" />
                                    <span className="subtext">15% discount of all orders </span>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <img src={Tick} alt="" />
                                    <span className="subtext">*Discount will be applicable on Dospeedo Family Prices</span>
                                </div> */}
                            </div>
                        </div>
                        <div className="p-6 pt-0">
                            {currentMemberShip?.membershipType?.name !== "Basic Plan" ? <button className="w-full btn_primary" onClick={() => displayRazorpay(subscriptionDataBasic[0]?.id, subscriptionDataBasic[0]?.price)}>
                                Subscribe Now
                            </button> :
                                <div className="btn_primary cursor-pointer"
                                // onClick={handleSubscribeBtn}
                                >
                                    <div className="flex gap-4 items-center px-4">
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="1" y="1" width="19" height="19" rx="9.5" fill="white" />
                                            <rect x="0.5" y="0.5" width="20" height="20" rx="10" stroke="black" stroke-opacity="0.1" />
                                            <path d="M4.86523 10.5741L8.17223 13.8741L15.5722 6.47412" stroke="#1B9075" stroke-width="2" />
                                        </svg>
                                        <div className="text-left">
                                            <p>Your current subscription!</p>
                                            <span className="subText1">You've unlocked exclusive perks & savings.</span>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                        {/* <div className="p-6 pt-0">
                            {currentMemberShip?.membershipType?.name === "Basic Plan" ? <button className="w-full btn_secondary" >
                                Cancel Your Subscription
                            </button> : ""}
                        </div> */}
                    </div>

                    {/* Advance Plan */}
                    <div className={`advance_subscription bg-yellow-100 w-full sm:w-3/4 lg:w-1/3 relative  ${currentMemberShip?.membershipType?.name === "Premium Plan" ? "z-40 subsShadow boxHeight" : ""}`}>
                        <div className="header">
                            <h2 className="text-xl font-semibold text-white text-center">{subscriptionDataPremium ? subscriptionDataPremium[0]?.name : null}</h2>
                            <p className="text-4xl md:text-5xl font-bold my-4 text-white text-center">{"Rs "}{subscriptionDataPremium ? subscriptionDataPremium[0]?.price : null}</p>
                            <p className=" mb-4 text-white text-center">{subscriptionDataPremium ? subscriptionDataPremium[0]?.duration : null}{" "}{subscriptionDataPremium ? subscriptionDataPremium[0]?.duration_type : null}</p>
                        </div>

                        {currentMemberShip?.membershipType?.name === "Premium Plan" && <div className="crownpositionPremium">
                            <span className="text-green-700 text-2xl">
                                <img src={CrownIcon} alt="" />
                            </span>
                        </div>}

                        <div className="p-6">
                            <div className="benefits text-gray-600 space-y-3 mb-4" dangerouslySetInnerHTML={{ __html: subscriptionDataPremium ? subscriptionDataPremium[0]?.benefits : "" }}>
                                {/* <div className="flex gap-4 items-center">
                                    <img src={Tick} alt="" />
                                    <span className="subtext">Express service delivery-Cleaning professional will arive within 45 mins after confirmation of order</span>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <img src={Tick} alt="" />
                                    <span className="subtext">15% discount of all orders </span>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <img src={Tick} alt="" />
                                    <span className="subtext">*Discount will be applicable on Dospeedo Family Prices</span>
                                </div> */}
                            </div>
                        </div>
                        <div className="p-6 pt-0">
                            {currentMemberShip?.membershipType?.name !== "Premium Plan" ? <button className="w-full btn_primary" onClick={() => displayRazorpay(subscriptionDataPremium[0]?.id, subscriptionDataPremium[0]?.price)}>
                                Subscribe Now
                            </button> :
                                <div className="btn_primary cursor-pointer"
                                // onClick={handleSubscribeBtn}
                                >
                                    <div className="flex gap-4 items-center px-4">
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="1" y="1" width="19" height="19" rx="9.5" fill="white" />
                                            <rect x="0.5" y="0.5" width="20" height="20" rx="10" stroke="black" stroke-opacity="0.1" />
                                            <path d="M4.86523 10.5741L8.17223 13.8741L15.5722 6.47412" stroke="#1B9075" stroke-width="2" />
                                        </svg>
                                        <div className="text-left">
                                            <p>Your current subscription!</p>
                                            <span className="subText1">You've unlocked exclusive perks & savings.</span>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                        {/* <div className="p-6 pt-0">
                            {currentMemberShip?.membershipType?.name === "Premium" ? <button className="w-full btn_secondary" >
                                Cancel Your Subscription
                            </button> : ""}
                        </div> */}
                    </div>
                </div>
                {/* <p className="mt-10 md:mt-20 text-center subtext2 px-4 mb-4">
                    Recurring billing - cancel anytime.
                </p> */}
            </div>
        </div>
    );
};

export default SubscriptionPage;
