import { createSlice } from "@reduxjs/toolkit";

const PRICE_INITIAL_STATE = {
    offerPrice: null,
    originalPrice: null,
    totalPrice: null,
    couponDetail: null,
}

const PriceSlice = createSlice({
    name: "price",
    initialState: PRICE_INITIAL_STATE,
    reducers: {
        setOfferPrice: (state, action) => {
            state.offerPrice = action.payload
        },
        setOriginalPrice: (state, action) => {
            state.originalPrice = action.payload
        },
        setTotalPrice: (state, action) => {
            state.totalPrice = action.payload
        },
        setCouponDetails: (state, action) => {
            state.couponDetail = action.payload
        }
    }
})

export const { setOfferPrice, setOriginalPrice, setTotalPrice, setCouponDetails } = PriceSlice.actions;
export default PriceSlice.reducer;