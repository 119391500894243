/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../../components/Header/index";
import Footer from "./Footer";

import * as apiEndpoint from "../../../apis/postlogin/PostloginApis"
import { getRequest } from "../../../interceptor/AxiosConfig";
import { useDispatch } from "react-redux";
import ErrorMessageHandling from "../../../utilsfunction/ErrorHandling";

import Error from "../../../components/Alert/Error"

// import reducer state
import { setUserDetails } from "../../../components/Reducers/UserReducer"
import { APIProvider } from "@vis.gl/react-google-maps";
// import Notifications from "./notification/Notification";

const CustomerLayout = () => {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    const firstVisit = localStorage.getItem("firstVisit");
    if (!firstVisit) {
      localStorage.setItem("firstVisit", "true");
      window.location.reload();
    }
    getUserDetails()
  }, []);

  // getting the details of the user
  const getUserDetails = async () => {
    await getRequest(`${apiEndpoint.userDetailsEndPoint}`).then((response) => {
      if (response.status === 200) {
        dispatch(setUserDetails(response.data.data))
      }
    }).catch((error) => {
      setErrorMessage(ErrorMessageHandling(error))
    })
  }

  return (
    <div>
      <Header />
      {/* Child component */}
      <div>
        {errorMessage && <Error errorMessage={errorMessage} />}
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <Outlet />
        </APIProvider>
      </div>
      <Footer currentYear={currentYear} />
    </div>
  );
};

export default CustomerLayout;
