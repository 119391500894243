import React, { useState } from 'react'
import BookingCard from "../../../../components/Cards/BookingCard"
import BookingDetailsModal from "../../../../components/modals/BookingDetailsModal";

const CancelledBooking = ({ bookingDetails, bookings }) => {
    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    return (
        <div className={`grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-2`}>
            {/* Card Header */}
            {bookingDetails?.map((booking, index) => (
                <BookingCard
                    bookingDetails={booking}
                    isCancelled={true}
                />
            ))}

            {isReviewModalOpen && <BookingDetailsModal
                bookingDetails={bookingDetails}
                isReviewModalOpen={isReviewModalOpen}
                setReviewModalOpen={setReviewModalOpen}
                i
            />
            }
        </div>
    )
}

export default CancelledBooking