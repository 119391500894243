/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ServiceModal from "../modals/ServiceListModal";

const ServiceCard = ({
  ServiceImg,
  ServiceHead,
  ServiceDescription,
  ButtonTitle,
  servicePrice,
  bookingServices
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="service_card p-6">
      <div className="service_body">
        <div className="serviceImageWrap relative">
          <img src={ServiceImg} alt={"Service img"} className="serviceImg" />
          <div className="service_price">
            <span>{"Rs "}{servicePrice}</span>
          </div>
        </div>
        <div className="service_head text-2xl font-bold text-gray-800 mb-4" title={ServiceHead}>
          {ServiceHead}
        </div>
        <div className="flex items-center justify-between">
          <div className="service_description py-2">
            {/* <span>{ServiceDescription}</span> */}
          </div>
          <div className="services_desc_view cursor-pointer" onClick={openModal}>View more</div>
          <ServiceModal isOpen={isModalOpen} onClose={closeModal} ServiceHead={ServiceHead}>
            <div className="list_services p-2" dangerouslySetInnerHTML={{ __html: ServiceDescription }}>
            </div>
          </ServiceModal>
        </div>
      </div>
      <button className="service_button mt-6" onClick={bookingServices}>{ButtonTitle}</button>
    </div>
  );
};

export default ServiceCard;
