/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import Header from "../../../components/Header/index";
import "../../../styles/home/home.css";

import VectorGrp from "../../../assets/home/cleaningStaff.png";
//import StarIcon from "../../../assets/home/star.svg";

import ArrowBtn from "../../../assets/home/ArrowBtn.svg";
import LocationIcon from "../../../assets/home/location.svg";
// import DropdownIcon from "../../../assets/arrows/arrowdowngreen.svg";

// offer component
import OfferCard from "../../../components/Cards/OfferCard";

import ServiceCard from "../../../components/Cards/ServiceCard";

// about service
import AboutUsImg from "../../../assets/about/aboutusimg.png";
import BaloonIcon from "../../../assets/about/baloon.svg";
import DiscountIcon from "../../../assets/about/discount.svg";
import GalleryIcon from "../../../assets/about/gallery.svg";

// download service
import AppleStoreIcon from "../../../assets/download/applestorebtn.svg";
import GoogleStoreIcon from "../../../assets/download/googlestorebtn.svg";
import LinkIcon from "../../../assets/download/link.svg";
import MobileImg from "../../../assets/download/mobileimg.png";

// testimonials
import RatingStarIconGray from "../../../assets/home/testimonials/stargray.svg";
import RatingStarIconYellow from "../../../assets/home/testimonials/staryellow.svg";
import InvertedComma from "../../../assets/home/testimonials/invertedcomma.svg";
import NextIcon from "../../../assets/home/testimonials/nexticon.svg";
import PreviousIcon from "../../../assets/home/testimonials/previousicon.svg";

// services expert
import EarningsIcon from "../../../assets/services/earnings.svg";
import EssentialsIcon from "../../../assets/services/essentials.svg";
import PeopleGroupIcon from "../../../assets/services/peopleGroup.svg";
import ExportImage from "../../../assets/services/service_expert.png";

import BlogCard from "../../../components/Cards/BlogCard";
import { getRequest } from "../../../interceptor/AxiosConfig";
import { homePageDataApiEndpoint } from "../../../apis/prelogin/PreloginApis";
import ErrorMessageHandling from "../../../utilsfunction/ErrorHandling";
import { formatDate } from "../../../utilsfunction/Functions";
import { Link, useNavigate } from "react-router-dom";

import * as routePath from "../../../routes";
import UserLocation from "../../../utilsfunction/GeoLocation";
import Error from "../../../components/Alert/Error";
import Footer from "../../../components/Footer/Footer";
import ReferSocialModal from "../../../components/modals/ReferViaSocialLinks";
import { setOfferDetails } from "../../../components/Reducers/OfferReducer";
import { useDispatch } from "react-redux";

const Home = () => {
  const base_url = process.env.REACT_APP_API_URL + "uploads/";

  const dispatch = useDispatch()

  // for notifications

  // for getting the location and error message
  const { userLocation, locationError, getUserLocation } = UserLocation();

  useEffect(() => {
    getUserLocation();
  }, []);

  const navigate = useNavigate();

  //const [activeIndex, setActiveIndex] = useState(0);

  const [errorMessage, setErrorMessage] = useState(
    locationError ? locationError : null
  );

  // const handleDotClick = (index) => {
  //   setActiveIndex(index);
  // };

  // for carousel dot buttons
  //const dots = [0, 1, 2];

  // home data
  const [services, setServicesData] = useState(null);
  const [blogs, setBlogsdata] = useState(null);
  const [testimonialData, setTestimonial] = useState(null);
  const [offers, setOffers] = useState(null);

  const [loader, setLoader] = useState(true);

  const getHomeData = async () => {
    setLoader(true);
    await getRequest(`${homePageDataApiEndpoint}`)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setServicesData(res.data.data.categories);
          setBlogsdata(res.data.data.blogs);
          setTestimonial(res.data.data.testimonials);
          setOffers(res.data.data.offers);
        }
      })
      .catch((error) => {
        setErrorMessage(ErrorMessageHandling(error));
      });
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? testimonialData.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const isLastSlide = currentIndex === testimonialData.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // calling api home component loads
  useEffect(() => {
    getHomeData();
  }, []);

  // service book function

  const bookService = (id) => {
    navigate(
      `/${routePath.CustomerLayoutPath}/${routePath.servicesListPath}`, { state: { categoryId: id } }
    );
  };

  // refer via social 
  const [isSocialRefer, setIsSocialRefer] = useState(false);

  const openModal = () => {
    setIsSocialRefer(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsSocialRefer(false);

    document.body.style.overflow = 'auto';
  };

  // on offers click
  const onOfferClick = (value) => {
    dispatch(setOfferDetails(value))
    navigate(`/${routePath.CustomerLayoutPath}/${routePath.servicesListPath}`)
  }

  return (
    <div className="home">
      {/* Header */}
      <Header />

      {errorMessage && <Error errorMessage={errorMessage} />}

      {/* Hero Section */}

      {loader ? null : (
        <>
          <section className="container mx-auto text-white carousel">
            <div className="carousel_content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center justify-between">
              <div className="left_content mb-8 gap-2 md:gap-4 md:mb-0 md:mr-8 w-full md:w-1/2">
                <h1 className="left_content_text text-2xl md:text-4xl font-bold leading-snug">
                  Simplifying cleaning{" "}
                  {/* <sup>
                    <img src={StarIcon} className="sup_image" alt="Star" />
                  </sup>{" "}<br /> */}
                  and empowering lives <br /> with a <span>click</span>
                </h1>
                <p className="left_content_subtext text-sm md:text-base">
                  DoSpeedo connects you with professional maids who make your home sparkle, anytime, anywhere and turn your life stress-free!
                </p>
                <div className="carousel_input w-6/12 relative flex md:flex-row items-center p-4">
                  <img
                    src={LocationIcon}
                    className="icon_location"
                    alt="location"
                  />
                  <div className="custom_select w-full border-none">
                    {userLocation?.country && (
                      <>
                        <span className="state">{userLocation?.state}</span>
                        {","}&nbsp;
                        <span className="country">{userLocation?.country}</span>
                      </>
                    )}
                  </div>
                  {/* <div>
                    <svg
                      width="1"
                      height="28"
                      viewBox="0 0 1 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="0.5"
                        y1="2.18556e-08"
                        x2="0.499999"
                        y2="28"
                        stroke="#474747"
                      />
                    </svg>
                  </div>
                  <div className="custom_service_btn w-full border-none md:max-w-sm">
                    <span className="text">Find your service</span>
                  </div>
                  {userLocation?.country && (
                    <img
                      className="custom_button"
                      src={ArrowBtn}
                      alt="search"
                      onClick={() =>
                        navigate(
                          `/${routePath.CustomerLayoutPath}/${routePath.servicesListPath}`
                        )
                      }
                    />
                  )} */}
                </div>

                <div className="carousel_input w-6/12 relative flex md:flex-row items-center p-4">
                  {/* <img
                    src={LocationIcon}
                    className="icon_location"
                    alt="location"
                  />
                  <div className="custom_select w-full border-none">
                    {userLocation?.country && (
                      <>
                        <span className="state">{userLocation?.state}</span>
                        {","}&nbsp;
                        <span className="country">{userLocation?.country}</span>
                      </>
                    )}
                  </div> */}
                  {/* <div>
                    <svg
                      width="1"
                      height="28"
                      viewBox="0 0 1 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="0.5"
                        y1="2.18556e-08"
                        x2="0.499999"
                        y2="28"
                        stroke="#474747"
                      />
                    </svg>
                  </div> */}
                  <div className="custom_service_btn w-full border-none md:max-w-sm">
                    <span className="text">Find your service</span>
                  </div>
                  {userLocation?.country && (
                    <img
                      className="custom_button"
                      src={ArrowBtn}
                      alt="search"
                      onClick={() =>
                        navigate(
                          `/${routePath.CustomerLayoutPath}/${routePath.servicesListPath}`
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div className="right_content w-full md:w-1/2 grid grid-cols-1">
                <img
                  src={VectorGrp}
                  alt="Cleaning"
                  className="h-40 md:h-auto mx-auto"
                />
              </div>
            </div>
            {/* <div className="carousel_button absolute bottom-0 transform -translate-x-1/2 flex space-x-2">
              {dots.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${index === activeIndex ? "active" : ""}`}
                  onClick={() => handleDotClick(index)}
                ></div>
              ))}
            </div> */}
          </section>

          {/* Offers Section  */}
          <section className="container mx-auto pt-20 bg-white">
            <div className="offers_section w-full">
              <div className="offer_head text-center mb-8">
                <h2 className="text-green-600 text-lg font-semibold">
                  Offers Available
                </h2>
                <h1 className="text-3xl md:text-4xl font-bold text-gray-800">
                  Exclusive Deals Await!
                </h1>
              </div>
              <div className="offers_body_content grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
                {/* offers */}
                {offers?.map((value, i) => {
                  return (
                    <>
                      <OfferCard
                        offerPercent={value?.discount + "%"}
                        offerText={value?.title}
                        offerSubtext={value?.description}
                        Icon={value?.image}
                        onClickFn={() => onOfferClick(value)}
                      />
                    </>
                  );
                })}
              </div>
            </div>
          </section>

          {/* Service Offers  */}
          <section id="services" className="container mx-auto pt-20 bg-white">
            <div className="services_section w-full flex flex-col items-center p-12">
              <div className="service_section_head text-center">
                <h2 className="text-green-600 text-lg font-semibold">
                  Our Services
                </h2>
                <h1 className="text-3xl font-bold text-gray-800 mb-8">
                  Unmatched Service Experience
                </h1>
              </div>
              <div className="w-full service_section_body grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {/* services */}
                {services?.map((value, _) => {
                  return (
                    <>
                      <ServiceCard
                        Icon={`${base_url}${value?.icon}`}
                        ServiceHead={
                          <>
                            <span className="first">
                              {value?.name?.split(" ")[0]}
                            </span>{" "}
                            <span className="second">
                              {value?.name?.split(" ")[1]}
                            </span>
                          </>
                        }
                        ServiceDescription1={value?.services}
                        ButtonTitle={"Book Now"}
                        onClickFn={() => bookService(value?.name)}
                      />
                    </>
                  );
                })}
              </div>
              <button
                className="seeall_button mt-20"
                onClick={() =>
                  navigate(
                    `/${routePath.CustomerLayoutPath}/${routePath.servicesListPath}`
                  )
                }
              >
                See All
              </button>
            </div>
          </section>

          {/* About Dospeedo */}
          <section className="container mx-auto py-20 bg-white text-white">
            <div className="about_us w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              <div className="about_left_section text-center">
                <img src={AboutUsImg} alt="About Us Section" />
              </div>
              <div className="about_right_section">
                <div className="about_head w-auto">
                  <h1 className="head">
                    Why choose <span>DoSpeedo?</span>
                  </h1>
                  <div className="description">
                    DoSpeedo is India's most trusted service partner for connecting you with professionally trained, reliable, and verified maids. Find the perfect maid that aligns with your requirements, in just a few clicks. All maids registered with us are Aadhar verified, have criminal background checks performed and are exceptionally trained in soft skills. Whether you're looking for a full-time, live-in, or occasional service, or even prefer male or female options, we tailor our offerings to suit your requirements.
                  </div>
                </div>
                <div className="about_body flex flex-col gap-4 justify-between">
                  <div className="text-center gap-4 flex flex-row justify-between items-center">
                    <img src={BaloonIcon} alt="" />
                    <span>Transparent Pricing</span>
                  </div>
                  <hr />
                  <div className="text-center gap-4 flex flex-row justify-between items-center">
                    <img src={DiscountIcon} alt="" />
                    <span>100% Verified Helpers</span>
                  </div>
                  <hr />
                  <div className="text-center gap-4 flex flex-row justify-between items-center">
                    <img src={GalleryIcon} alt="" />
                    <span>Best Customer Support</span>
                  </div>
                </div>
                <div className="about_footer">
                  <button className="footer_btn">Let's Start</button>
                </div>
              </div>
            </div>
          </section>

          {/* App Promotion  */}
          <section className="container mx-auto pt-20 download_section">
            <div className="download_app_section w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              <div className="top_content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                <div className="left_section">
                  <h2 className="text-3xl font-bold text-gray-800 mb-4">
                    {/* There is more to <span>Love</span> in the app */}
                    Let's make cleaning Super Easy — Find your <span>MAID</span> today!
                  </h2>
                  <span className="testimonial_head">Download our app for FREE and hire your maid in seconds.</span>
                  <div className="mt-6 flex">
                    <a href="#" className="mr-4">
                      <img src={AppleStoreIcon} alt="App Store" />
                    </a>
                    <Link to={`https://play.google.com/store/apps/details?id=com.dospeedo`} target="_blank">
                      <img src={GoogleStoreIcon} alt="Google Play" />
                    </Link>
                  </div>
                </div>
                <div className="right_section">
                  <img src={MobileImg} alt="Mobile" />
                </div>
              </div>
              <div className="footer_content">
                <img src={LinkIcon} alt="" />

                <div className="content">
                  <h6>Refer and Earn Big!</h6>
                  <div>
                    Refer us your friend and get an assured reward the first time they transact.
                  </div>
                </div>

                <button className="footer_btn"
                  onClick={() => { openModal() }}
                >Share Via Link</button>
              </div>
            </div>

            {isSocialRefer && <ReferSocialModal closeModal={closeModal} />}
          </section>

          {/* Testimonials */}
          <section className="container mx-auto py-20 bg-white" id="testimonials">
            <div className="testimonials_section flex flex-col gap-4">
              <span className="testimonial_head">Testimonials</span>
              <h2 className="testimonial_desc">See what users say about us</h2>
              <div className="testimonial_body">
                <div className="testimonial_rank flex justify-center">
                  {[...Array(5)].map((_, i) => (
                    <span key={i} className={`h-6 w-6`}>
                      <img
                        src={`${i < testimonialData[currentIndex]?.rating
                          ? RatingStarIconYellow
                          : RatingStarIconGray
                          }`}
                        alt={"Rating"}
                      />
                    </span>
                  ))}
                </div>
                <img
                  src={InvertedComma}
                  alt={"Inverted Comma"}
                  className="inverted_comma"
                  width={"120px"}
                />
                <div className="testimonial_text pb-4">
                  <p className="pb-4">
                    {testimonialData[currentIndex]?.review}
                  </p>
                  <hr></hr>
                </div>
                <div className="flex testimonial_user_section items-center gap-4">
                  <button onClick={handlePrev} className="p-2 btn_previous">
                    <img src={PreviousIcon} alt={"Previous"} />
                  </button>
                  <div className="bg-white items-center flex gap-2 md:gap-6 lg:gap-8">
                    {testimonialData?.map((_, i) => {
                      return (
                        <div
                          className={`${currentIndex === i
                            ? "flex flex-col items-center gap-2"
                            : ""
                            }`}
                        >
                          <img
                            src={`${base_url}${testimonialData[i].User.profile_picture}`}
                            alt={""}
                            className={`${currentIndex === i
                              ? "active_testimonial rounded-full mx-auto"
                              : "not_activetestimonials h-16 w-16"
                              }`}
                          />
                          {currentIndex === i && (
                            <h4 className="testimonial_user_name">
                              {testimonialData[i].User.full_name}
                            </h4>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <button onClick={handleNext} className="p-2 btn_next">
                    <img src={NextIcon} alt={"Next"} />
                  </button>
                </div>
              </div>
            </div>
          </section>

          {/* Service Expert Call-to-Action */}
          <section section className="container service_expert mx-auto">
            <div className="service_expert_section opacity-95 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
              <div className="left_section py-12">
                <div className="top">
                  <h2 className="top_head text-3xl text-white font-bold mb-4">
                    Are you an Expert <span>Maid?</span>
                  </h2>
                  <div className="section_text1">
                    <img src={EarningsIcon} alt={"Earning"} />
                    <div>
                      <div className="service_exp_head">
                        Multiply Your Earnings
                      </div>
                      <div className="service_exp_desc">
                        With Dospeedo, you can work with more jobs and increase your income. No bargaining is guaranteed.
                      </div>
                    </div>
                  </div>
                  <div className="section_text2">
                    <img src={EssentialsIcon} alt={"Essentials"} />
                    <div>
                      <div className="service_exp_head">
                        Endless Opportunities
                      </div>
                      <div className="service_exp_desc">
                        Discover a wide range of job opportunities in your area, allowing you to grow your clientele effortlessly.
                      </div>
                    </div>
                  </div>
                  <div className="section_text3">
                    <img src={PeopleGroupIcon} alt={"Group"} />
                    <div>
                      <div className="service_exp_head">
                        Timely, Flexible Work
                      </div>
                      <div className="service_exp_desc">
                        Enjoy the freedom to choose your work hours, making it easier to balance your work and personal life.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom">
                  <button className="bottom_btn">Join Us Now</button>
                </div>
              </div>
              <div className="right_section">
                <img src={ExportImage} alt={"Service Expert"} />
              </div>
            </div>
          </section>

          {/* Blog Section  */}
          <section section className="container mx-auto bg-white py-12" id="blogs">
            <div className="blog_section flex flex-col gap-4 items-center">
              <span className="blogs_head">Blogs</span>
              <h1 className="text-3xl font-bold text-gray-800 mb-8">
                Expert Advice for a Spotless Home
              </h1>
              <h2 className="blogs_desc mb-8">
                {/* Latest from our Blog */}
                {/* Lorem Ipsum is a dummy text */}
              </h2>
              <div className="blog_section_card grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8">
                {blogs?.map((value, index) => {
                  return (
                    <BlogCard
                      date={formatDate(value.created_at)}
                      heading={value.title}
                      blogImg={value.banner_image}
                      onClickFn={() => navigate(`/${routePath.blogDetailsPath}/${value.id}`)}
                    />
                  );
                })}
              </div>
              {/* <div>
                <button className="blog_button" onClick={() => navigate(`/${routePath.blogDetailsPath}`)}>See All</button>
              </div> */}
            </div>
          </section>

          <Footer />
        </>
      )}
    </div>
  );
};

export default Home;