import React from "react";
import Header from "../../../components/Header/index";
import Footer from "../../../components/Footer/Footer";
import BlogImg from "../../../assets/blog.png"

const BlogDetails = () => {
    return (
        <>
            <Header />
            <div className="container mx-auto min-h-screen bg-gray-100 py-8">
                <div className="w-full">
                    {/* Main Content */}
                    <div className="col-span-2 bg-white p-6">
                        {/* Blog Image */}
                        <div className="flex justify-center pb-6">
                            <h1 className="uppercase blogheading">Single Blog</h1>
                        </div>
                        <div className="relative">
                            <img
                                src={BlogImg}
                                alt="Cleaning Service"
                                className="w-full h-auto "
                            />
                            <div className="absolute inset-0 bg-black bg-opacity-70"></div>
                            <div className="absolute inset-0 flex items-center justify-center">
                                <span className="text-white heading_text">Lorem ipsum dolor sit ames is a dummy text</span>
                            </div>
                        </div>

                        {/* Blog Content */}
                        <div className="mt-4">
                            <p className="mb-4 content_text">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </p>

                            <h2 className="text-left content_text3">
                                Where Does it Come From?
                            </h2>
                            <p className="mb-4 content_text">
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BlogDetails;
