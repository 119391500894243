import React from "react";

const AddOnModal = ({ service, isOpen, onClose, onAdd, selectedAddOns, setSelectedAddOns, addOns }) => {

    const handleAddOnChange = (addOnId) => {
        setSelectedAddOns((prev) =>
            prev.includes(addOnId)
                ? prev.filter((id) => id !== addOnId)
                : [...prev, addOnId]
        );
    };

    const handleAdd = () => {
        // Calculate total add-ons cost
        const selectedAddOnsDetails = addOns?.filter((addOn) =>
            selectedAddOns.includes(addOn?.AddOn?.id)
        );
        onAdd(service, selectedAddOnsDetails);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 modal_dialog">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full">
                <div className="p-4 border-b modal_header">
                    <h2 className="text-white">Choose Add-ons for {service.name}</h2>
                </div>
                <div className="p-4 modal_body">
                    {addOns.map((addOn) => (
                        <div key={addOn.id} className="flex items-center justify-between mb-2">
                            <span>{addOn?.AddOn?.name}</span>
                            <div className="flex items-center">
                                <span className="mr-2 text-sm text-gray-600">₹{addOn?.AddOn?.price}</span>
                                <input
                                    type="checkbox"
                                    checked={selectedAddOns.includes(addOn?.AddOn?.id)}
                                    onChange={() => handleAddOnChange(addOn?.AddOn?.id)}
                                    className="form-checkbox h-5 w-5 text-indigo-600"
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="p-4 border-t flex justify-end space-x-2">
                    <button
                        onClick={onClose}
                        className="btn_secondary"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleAdd}
                        className="btn_primary"
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddOnModal;
