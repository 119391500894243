// src/store/bookAgainSlice.js

import { createSlice } from '@reduxjs/toolkit';
// import { LocalStorage } from "../../utilsfunction/Encrypt";

// const localstorage = new LocalStorage()
const initialState = {
    bookAgainAddress: null,
    bookingDetails: null,
    selectedServiceType: null,
    selectedAddOn: null,
    // bookAgainAddress: localstorage.getItem("bookingAgainAddress") ? localstorage.getItem("bookingAgainAddress") : null,
};

const bookAgainSlice = createSlice({
    name: 'bookAgain',
    initialState,
    reducers: {
        setBookAgainAddress: (state, action) => {
            state.bookAgainAddress = action.payload;
            // localstorage.setItem("bookingAgainAddress", action.payload)
        },
        setBookingDetails: (state, action) => {
            state.bookingDetails = action.payload;
        },
        setSelectedServiceType: (state, action) => {
            state.selectedServiceType = action.payload;
        },
        setSelectedAddOn: (state, action) => {
            state.selectedAddOn = action.payload;
        },
    },
});

export const { setBookAgainAddress, setBookingDetails, setSelectedServiceType, setSelectedAddOn } = bookAgainSlice.actions;

export default bookAgainSlice.reducer;
