/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../../../styles/postlogin/services.css";
import ServiceCard from "../../../../components/Cards/ServicesAll";

// services all
// import Bathroom from "../../../../assets/services/Imagebathroom.png"
// import Kitchen from "../../../../assets/services/Imagekitchen.png"
// import House from "../../../../assets/services/Imagehouse.png"
import { getRequest } from "../../../../interceptor/AxiosConfig";
import { servicesListEndpoint } from "../../../../apis/postlogin/PostloginApis";

import * as routePath from "../../../../routes"
import { useLocation, useNavigate } from "react-router-dom";

const Services = () => {
  // base url for images
  const base_url = process.env.REACT_APP_API_URL + "uploads/"
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);

  const location = useLocation()

  const categoryId = location.state?.categoryId

  const [activeLink, setActiveLink] = useState(categoryId ? categoryId : "Residential Cleaning");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const [servicesList, setServicesList] = useState([])
  const [residentialCategoriesList, setResidentialCategories] = useState([])
  const [residentialService, setResidentialServices] = useState(null)

  const [commercialServicesList, setCommercialServiceslist] = useState([])
  const [occassionServicesList, setOccassionServiceslist] = useState([])

  // api call for getting the list of services available

  const getServices = async () => {
    setLoader(true)
    await getRequest(`${servicesListEndpoint}`)
      .then((response) => {
        setLoader(false)
        if (response.status === 200) {
          setServicesList(response.data.data);

          // let commercialServicesList = response.data.data.filter((item) => item.id == "2")
          // setCommercialServiceslist(commercialServicesList)

          // let occassionalServicesList = response.data.data.filter((item) => item.id == "4")
          // setOccassionServiceslist(occassionalServicesList)

          // let residentialCategoriesList = response.data.data.filter((item) => item.id == "1")
          // setResidentialCategories(residentialCategoriesList)

          let commercialServicesList = response.data.data[2]
          setCommercialServiceslist(commercialServicesList)

          let occassionalServicesList = response.data.data[1]
          setOccassionServiceslist(occassionalServicesList)

          let residentialCategoriesList = response.data.data[0]
          setResidentialCategories(residentialCategoriesList)
        }
      }).catch((error) => {
        setLoader(false)
      })
  }

  // calling function on component load
  useEffect(() => {
    getServices()
  }, [])

  // function to handle booking navigation
  const handleBookingNavigation = (id) => {
    navigate(`/${routePath.CustomerLayoutPath}/${routePath.servicesBookingPath}/${id}`)
  }

  // for dropdown option residential services
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [residentialServicesList, setResidentialServicesList] = useState([])

  const handleOptionClick = (option) => {
    setResidentialServices(option);
    setIsOpen(false);
    let services = []
    for (let i = 0; i <= residentialCategoriesList?.services?.length - 1; i++) {
      if (+residentialCategoriesList?.services[i].sub_category_id == option?.id) {
        services.push(residentialCategoriesList?.services[i])
      }
    }
    setResidentialServicesList(services)
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {loader ? <div className="m-auto">Loading</div> :
        <section className="container mx-auto min-h-screen services">
          <div className="p-4 ps-0">
            <div className="heading_first">
              All <span className="heading_second">Services</span>
            </div>
          </div>
          <div className="flex flex-row justify-evenly services_topbar items-center">
            {servicesList?.map((value, _) => {
              return <div
                className={` cursor-pointer ${activeLink === value?.name ? "activeHead" : "head_link"
                  }`}
              >
                <span onClick={() => handleLinkClick(value?.name)}>
                  {value?.name}
                </span>
              </div>
            })}
          </div>

          <div className="flex flex-col gap-4 mt-4">
            {activeLink === residentialCategoriesList?.name && <div>
              <div className="tabtitlebg relative w-64" ref={dropdownRef}>
                <button
                  onClick={toggleDropdown}
                  className="w-full text-left px-4 py-2 head_link"
                >
                  {residentialService ? residentialService?.name : "Select an option"}
                </button>

                {isOpen && (
                  <ul className="absolute w-full mt-1 bg-white max-h-60 overflow-auto z-10">
                    {residentialCategoriesList?.subCategories?.map((option) => (
                      <>
                        <li
                          key={option.id}
                          className="px-4 py-2 hover:bg-indigo-100 cursor-pointer head_link"
                          onClick={() => handleOptionClick(option)}
                        >
                          {option.name}
                        </li>
                      </>
                    ))}
                  </ul>
                )}
              </div>
            </div>}

            <div className="services_card mt-4 ps-0 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 items-center justify-between">
              {activeLink === residentialCategoriesList?.name && residentialServicesList?.map((value, _) => {
                return <ServiceCard
                  ServiceImg={`${base_url}${value.image}`}
                  ServiceHead={value.name}
                  ServiceDescription={value.description}
                  ButtonTitle={"Book Now"}
                  servicePrice={value.price}
                  bookingServices={() => handleBookingNavigation(value.id)}
                />
              })}
            </div>
          </div>

          <div className="services_card mt-4 p-4 ps-0 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 items-center justify-between">
            {activeLink === commercialServicesList?.name && commercialServicesList?.services?.map((value, _) => {
              return <ServiceCard
                ServiceImg={`${base_url}${value.image}`}
                ServiceHead={value.name}
                ServiceDescription={value.description}
                ButtonTitle={"Book Now"}
                servicePrice={value.price}
                bookingServices={() => handleBookingNavigation(value.id)}
              />
            })}
          </div>

          <div className="services_card mt-4 p-4 ps-0 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 items-center justify-between">
            {activeLink === occassionServicesList?.name && occassionServicesList[0]?.services?.map((value, _) => {
              return <ServiceCard
                ServiceImg={`${base_url}${value.image}`}
                ServiceHead={value.name}
                ServiceDescription={value.description}
                ButtonTitle={"Book Now"}
                servicePrice={value.final_price}
                bookingServices={() => handleBookingNavigation(value.id)}
              />
            })}
          </div>
        </section>}
    </div>
  );
};

export default Services;
