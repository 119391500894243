import React from 'react'

const OfferCard = ({
    offerPercent,
    offerText,
    offerSubtext,
    Icon,
    onClickFn
}) => {
    return (
        <div className="card rounded-lg text-center grid grid-cols-2 cursor-pointer" onClick={onClickFn}>
            <div className="left_content p-6">
                <div className="offer_percent">
                    <span>{offerPercent}</span><sup>OFF</sup>
                </div>
                <div className="offer_text">{offerText}</div>
                <p className="offer_subtext">{offerSubtext}</p>
            </div>
            <div className="right_content">
                <img src={Icon} alt="Offers" />
            </div>
        </div>
    )
}


export default OfferCard