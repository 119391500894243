// convert date
export const formatDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const options = { year: "numeric", month: "short", day: "numeric" };

  return date.toLocaleDateString("en-US", options);
}


// format date from yyyy-mm-dd to Day-date Month Year
export const formateDateTwo = (dateString) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const date = new Date(dateString);

  const dayOfWeek = days[date.getUTCDay()];
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${dayOfWeek}-${day} ${month} ${year}`;
}

// format date from yyyy-mm-dd to date Month Year
export const formateDateThree = (dateString) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const date = new Date(dateString);

  // const dayOfWeek = days[date.getUTCDay()];
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
}

// convert time to hh:mm format
export const convertTo12Hour = (time24) => {
  let [hours, minutes] = time24.split(':');
  hours = parseInt(hours, 10);

  let period = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
}

// convert first letter to cap
export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// mm/dd/yyyy to yyyy-mm-dd
export const convertDate = (inputDate) => {
  if (!inputDate) return null;

  const [day, month, year] = inputDate.split('/');
  return `${year}-${month}-${day}`;
}
