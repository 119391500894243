/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ProgressBar from "../../../../../components/progressbar/ProgressBar";

import "../../../../../styles/postlogin/services.css"

// import api endpoint
import * as apiEndpoint from "../../../../../apis/postlogin/PostloginApis"

// import routes
import * as routePath from "../../../../../routes"

// img imports
import RightArrow from "../../../../../assets/icons/arrowright.svg"

// import MapImage from "../../../../../assets/map.png"
// import Error from "../../../../../components/Alert/Error";
import FormError from "../../../../../components/Alert/FormError";
import { useNavigate, useParams } from "react-router-dom";
import { LocalStorage } from "../../../../../utilsfunction/Encrypt";
import ServiceOffCanvas, { ConfirmBookingOffcanvas } from "../ServiceOffcanvas";

import Logo from "../../../../../assets/dospeedo.svg"
import { getRequest, postRequest } from "../../../../../interceptor/AxiosConfig";
import ErrorMessageHandling from "../../../../../utilsfunction/ErrorHandling";
import Error from "../../../../../components/Alert/Error";
import { useDispatch, useSelector } from "react-redux";
import BookingConfirmModal from "../BookingConfirmModal";

// google map import
import { Map, Marker } from '@vis.gl/react-google-maps';
import { setBookAgainAddress, setBookingDetails, setSelectedServiceType, setSelectedAddOn } from "../../../../../components/Reducers/AddAddressReducer";
import { setCouponDetails, setOfferPrice, setOriginalPrice, setTotalPrice } from "../../../../../components/Reducers/Price";

import AddOnModal from "./AddOnModal";

// import Slider from "react-slick";

// import DoSpeedoMarker from "../../../../../assets/location_pin.png"
// import Maps from "../../../../../components/map/Maps";

const BookService = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { id } = useParams()
    const localstorage = new LocalStorage()
    const [errorMessage, setErrorMessage] = useState(null);

    // const base_url = process.env.REACT_APP_API_URL + "uploads/";

    // booking details data on selecting the address

    const { bookingDetails, selectedServiceType, selectedAddOn } = useSelector((state) => state?.addressReducer);

    // for toggling the offcanvas booking service
    const [isOpen, setIsOpen] = useState(false);

    // for toggling the offcanvas confirm booking service
    const [isOpenBookingConfirmation, setIsOpenBookingConfirmation] = useState(false);
    const [isExpandedOnViewMore, setIsExpandedOnViewMore] = useState(false);

    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selectedServiceType ? selectedServiceType : "");
    const dropdownRef = useRef(null);

    const dropdownRefAddressType = useRef(null);

    const [addOnList, setAddOnList] = useState(null);
    const [serviceTypes, setServiceTypes] = useState(null)

    // for add ons
    const [selectedAddOns, setSelectedAddOns] = useState([]);
    const [cart, setCart] = useState(selectedAddOn ? selectedAddOn : []);

    // getting the user details from reducer
    const { userDetails } = useSelector((state) => state?.userReducer);

    // getting the address
    const { bookAgainAddress } = useSelector((state) => state?.addressReducer);

    const [isAddressTypeVisible, setAddressTypeVisible] = useState(false);
    const [addressTypeOption, setAddressTypeOption] = useState(bookAgainAddress?.type ? bookAgainAddress?.type : null);

    // add ons modal
    const [addOnModalOpen, setAddonModalOpen] = useState(false);

    const bookingInitialState = {
        service_id: 0,
        date: bookingDetails?.date ? bookingDetails?.date : null,
        start_time: bookingDetails?.start_time ? bookingDetails?.start_time : null,
        location: null,
        address: bookAgainAddress?.block ? bookAgainAddress?.block : null,
        flat_number: bookAgainAddress?.house_number ? bookAgainAddress?.house_number : null,
        state: bookAgainAddress?.location_name ? bookAgainAddress?.location_name : null,
        type: bookAgainAddress?.type ? bookAgainAddress?.type : null,
        latitude: 0,
        longitude: 0,
        note: bookingDetails?.note ? bookingDetails?.note : null,
        service_type_id: bookingDetails?.service_type_id ? bookingDetails?.service_type_id : null,
        payment_method: null,
        payment_status: null,
        payment_transaction_id: null,
        payment_transaction_response: null
    }

    const [bookingValues, setBookingValues] = useState(bookingInitialState)
    // const [errorMessage, setErrorMessage] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    // handle modal for confirm booking
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handlePaymentSelect = () => {
        setIsOpen(!isOpen)
        setIsOpenBookingConfirmation(!isOpenBookingConfirmation)
        setIsExpandedOnViewMore(!isExpandedOnViewMore)
    }

    const handleClose = () => {
        setIsModalOpen(!isModalOpen);
    };

    //  setting the booking fields values
    const onHandleChangeBooking = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setBookingValues({ ...bookingValues, [name]: value });
    };

    // validating the fields
    const validate = (values) => {
        const errors = {};
        if (!values.date) {
            errors.date = "Date is required!";
        }
        if (!values.start_time) {
            errors.start_time = "Date is required!";
        }
        // if (!values.service_type_id) {
        //     errors.service_type_id = "Date is required!";
        // }
        // if (!values.note) {
        //     errors.note = "Note is required!";
        // }
        if (!values.address) {
            errors.address = "Apartment/Road/Area is required!"
        }
        if (!values.flat_number) {
            errors.flat_number = "House/Flat number is required!"
        }
        if (!values.state) {
            errors.state = "Location name is required!"
        }
        if (!values.type) {
            errors.type = "Address type is required!"
        }
        return errors;
    };

    // function for handling create button event
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(bookingValues));
        setIsSubmit(true);
    };

    // create booking
    const createBooking = () => {
        setIsOpen(!isOpen)
        localstorage.setItem('bookingDetails', bookingValues);
        localstorage.setItem('addOns', cart)
    }

    useEffect(() => {
        // if there is no errors login() function will be called
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            createBooking()
        }
    }, [formErrors, isSubmit]);


    // function to handle clicking outside of the input and dropdown
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }

        if (dropdownRefAddressType.current && !dropdownRefAddressType.current.contains(event.target)) {
            setAddressTypeVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleFocus = () => {
        setDropdownVisible(true);
    };

    const handleAddressTypeSelect = () => {
        setAddressTypeVisible(true)
    }

    const [serviceTypePrice, setServiceTypePrice] = useState(null)

    const handleOptionClick = (option) => {
        setBookingValues({ ...bookingValues, "service_type_id": option?.id });
        setSelectedOption(option?.value + " - Rs " + option?.price);
        setDropdownVisible(false);
        setServiceTypePrice(option.price)
    };

    const handleAddressTypeClick = (option) => {
        setBookingValues({ ...bookingValues, "type": option });
        setAddressTypeOption(option);
        setAddressTypeVisible(false);
    };

    // setting the amount
    const [bookingServiceDetails, SetBookingServicesDetails] = useState(null)

    const getServiceDetails = async () => {
        await getRequest(`${apiEndpoint.serviceDetailsEndpoint}/${id}`).then((response) => {
            if (response.status === 200) {
                SetBookingServicesDetails(response.data.data)
                setAddOnList(response.data.data.ServiceAddOns)
                setServiceTypes(response.data.data.service_types)
            }
        }).catch((error) => {
            setErrorMessage(ErrorMessageHandling(error))
        })
    }

    // getting the referral discount if any
    const [referalOfferDiscount, setReferalOfferDiscount] = useState(null);
    const [membershipPlanDiscount, setMembershipPlanDiscount] = useState(null);

    const getReferralDiscount = async () => {
        await getRequest(`${apiEndpoint.activeOfferDiscountApiEndpoint}`).then((res) => {
            if (res.status === 200) {
                setReferalOfferDiscount(res.data.data)
                setMembershipPlanDiscount(res.data.active_plan)
            }
        }).catch((err) => {
            setErrorMessage(ErrorMessageHandling(err))
        })
    }

    useEffect(() => {
        getServiceDetails();
        getReferralDiscount()
    }, [])

    // for offers state

    const { offerDetails } = useSelector((state) => state?.offerReducer);

    const { couponDetails } = useSelector((state) => state?.priceReducer);

    // adding the addons
    const handleAdd = (service, addOns) => {
        const totalAddOnsPrice = addOns.reduce((sum, addOn) => sum + (+addOn?.AddOn?.price), 0);
        let addOnsIds = []
        for (let addOn of addOns) {
            addOnsIds.push(addOn.add_on_id)
        }

        let servicePrice = service.price
        const cartItem = {
            service: service.name,
            basePrice: serviceTypePrice ? serviceTypePrice : servicePrice,
            addOns: addOns,
            addOnsIds: addOnsIds,
            totalPrice: (serviceTypePrice ? +serviceTypePrice : +servicePrice) + (+totalAddOnsPrice),
        };
        setCart([...cart, cartItem]);
    };

    // create booking
    const bookingService = async (data) => {
        await postRequest(`${apiEndpoint.bookServiceEndpoint}`, data)
            .then((res) => {
                if (res.status === 201) {
                    // setErrorMessage(result.data.description);
                    setIsOpen(!isOpen)
                    setIsOpenBookingConfirmation(!isOpenBookingConfirmation)
                    setIsModalOpen(!isModalOpen);
                    // getCurrentBooking()
                }
            }).catch((err) => {
                setErrorMessage(ErrorMessageHandling(err))
            })
    }

    const activeMembershipDiscount = membershipPlanDiscount ? 15 : 0;

    // for getting the offer price information
    const offer = {
        discountPercentage: offerDetails?.discount && referalOfferDiscount?.discount_type?.discount_value ? (+offerDetails?.discount + +referalOfferDiscount?.discount_type?.discount_value + activeMembershipDiscount) : offerDetails?.discount ? +offerDetails?.discount + activeMembershipDiscount : referalOfferDiscount?.discount_type?.discount_value ? +referalOfferDiscount?.discount_type?.discount_value + activeMembershipDiscount : activeMembershipDiscount // discount from offer
    };

    // calculate total price from the last item in the cart
    const originalPrice = +cart[cart.length - 1]?.totalPrice ? +cart[cart.length - 1]?.totalPrice : serviceTypePrice ? serviceTypePrice : +bookingServiceDetails?.price;

    dispatch(setOriginalPrice(+cart[cart.length - 1]?.basePrice ? +cart[cart.length - 1]?.basePrice : serviceTypePrice ? serviceTypePrice : +bookingServiceDetails?.price))

    // apply the offer if available
    let finalPriceAfterOffer = originalPrice;

    // Calculate final price considering both offer and coupon
    finalPriceAfterOffer = calculateFinalPrice(originalPrice, offer.discountPercentage, couponDetails?.coupon);
    dispatch(setTotalPrice(finalPriceAfterOffer))

    // for coupon code
    const [couponCode, setCouponCode] = useState(null)

    const [couponCheckMessage, setCouponCheckMessage] = useState(null)

    // for verifying the coupon code
    const getCouponDetails = async () => {
        await postRequest(`${apiEndpoint.checkCouponEndpoint}`, {
            coupon_code: couponCode
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setCouponDetails(res.data.data))
                }
            }).catch((error) => {
                setCouponCheckMessage(ErrorMessageHandling(error))
            })
    }

    // payment gateway integration with razorpay and to load external script
    function loadScript(src) {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                reject(new Error(`Failed to load script: ${src}`));
            };
            document.body.appendChild(script);
        });
    }

    // Function to calculate final price with offers and coupons applied
    function calculateFinalPrice(originalPrice, offerDiscount = 0, couponDiscount = 0) {
        let discountedPrice = offerDiscount || couponDiscount ? originalPrice : 0;

        // Apply offer discount first
        if (offerDiscount > 0) {
            discountedPrice = +discountedPrice - +((offerDiscount / 100) * originalPrice)
            dispatch(setOfferPrice(+((offerDiscount / 100) * originalPrice)))
        }
        // Apply coupon discount on the discounted price
        if (couponDiscount > 0) {
            discountedPrice = +discountedPrice - +((couponDiscount / 100) * discountedPrice);
            dispatch(setOfferPrice(+((couponDiscount / 100) * discountedPrice)))
        }

        return discountedPrice;
    }

    // function to display razorpay payment gateway
    async function displayRazorpay() {
        try {
            // load the Razorpay checkout script
            const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                setErrorMessage("Razorpay SDK failed to load");
                return;
            }

            const activeMembershipDiscount = membershipPlanDiscount ? 15 : 0;

            // for getting the offer price information
            const offer = {
                discountPercentage: offerDetails?.discount && referalOfferDiscount?.discount_type?.discount_value ? (+offerDetails?.discount + +referalOfferDiscount?.discount_type?.discount_value + activeMembershipDiscount) : offerDetails?.discount ? +offerDetails?.discount + activeMembershipDiscount : referalOfferDiscount?.discount_type?.discount_value ? +referalOfferDiscount?.discount_type?.discount_value + activeMembershipDiscount : activeMembershipDiscount // discount from offer
            };

            // calculate total price from the last item in the cart
            // const originalPrice = +cart[cart.length - 1]?.totalPrice;
            const originalPrice = +cart[cart.length - 1]?.totalPrice ? +cart[cart.length - 1]?.totalPrice : serviceTypePrice ? serviceTypePrice : +bookingServiceDetails?.price;

            dispatch(setOriginalPrice(originalPrice))

            // apply the offer if available
            let finalPrice = originalPrice;
            // if (offer && offer.discountPercentage) {
            //     const discount = (offer.discountPercentage / 100) * originalPrice;
            //     finalPrice = originalPrice - discount;
            // }

            // Calculate final price considering both offer and coupon
            finalPrice = calculateFinalPrice(originalPrice, offer.discountPercentage, couponDetails?.coupon,);

            // details for razorpay order
            let orderDetailsForRazorPay;
            try {
                orderDetailsForRazorPay = await postRequest(`${apiEndpoint.razorpayOrderIdEndpoint}`, { amount: finalPrice ? finalPrice : originalPrice });
            } catch (e) {
                setIsOpen(!isOpen);
                return; // return on error
            }

            const { id: order_id, amount, currency } = orderDetailsForRazorPay.data;

            // payment options
            const options = {
                key: process.env.REACT_APP_RAZOR_PAY_KEY, // razorpay Key ID
                amount: amount ? amount : finalPrice ? finalPrice * 100 : originalPrice * 100, // use the final price after discount
                currency: currency ? currency : "INR",
                name: "DoSpeedo",
                description: "Service Transaction",
                image: Logo,
                order_id: order_id,
                handler: async function (response) {
                    try {
                        // data to be sent to the server
                        const data = {
                            service_id: id,
                            date: bookingValues?.date,
                            start_time: bookingValues?.start_time,
                            location: bookingValues?.address + " - " + bookingValues?.flat_number + " - " + bookingValues?.state,
                            latitude: null,
                            longitude: null,
                            note: bookingValues?.note,
                            payment_method: "razorpay",
                            payment_status: response.razorpay_status ? response.razorpay_status : "success",
                            payment_transaction_id: response.razorpay_payment_id,
                            payment_transaction_response: response,
                            service_add_on_ids: cart[cart.length - 1].addOnsIds,
                            total_price: originalPrice, // original price
                            service_type_id: bookingValues?.service_type_id,
                            coupon_code: couponCode ? couponCode : null,
                            discount_price: offer.discountPercentage ? (offer.discountPercentage / 100) * originalPrice : null,
                            offer_id: offer.id ? offer.id : null,
                            final_price: finalPrice ? finalPrice : null, // send the final price after discount
                            my_discount_id: referalOfferDiscount ? referalOfferDiscount?.id : null
                        };

                        // send the payment data to your server
                        await bookingService(data); // ensure this is awaited

                    } catch (error) {
                        setErrorMessage(`Payment handler error: ${error.message}`);
                    }
                },
                prefill: {
                    name: userDetails?.full_name,
                    email: userDetails?.email,
                    contact: userDetails?.phone_number,
                },
                notes: {
                    address: "DoSpeedo Corporate Office",
                },
                theme: {
                    color: "#1b9075",
                }
            };

            // initialize the Razorpay payment
            const paymentObject = new window.Razorpay(options);

            // handle payment failure
            paymentObject.on('payment.failed', function (response) {
                setErrorMessage(`Payment failed: ${response.error.description}`);
            });

            // open the Razorpay checkout window
            paymentObject.open();
        } catch (error) {
            setErrorMessage(`Error in payment process: ${error.message}`);
        }
    }


    const userLocation = localstorage.getItem("userLocation")

    // location lat and lon
    const markerLocation = { lat: userLocation?.lat, lng: userLocation?.lon }

    // const settings = {
    //     className: "center",
    //     centerMode: true,
    //     infinite: true,
    //     centerPadding: "60px",
    //     slidesToShow: 3,
    //     speed: 500
    // };

    // // add ons functionality

    // const handleAdd1 = () => {
    //     // Calculate total add-ons cost
    //     const selectedAddOnsDetails = addOnList?.filter((addOn) =>
    //         selectedAddOns.includes(addOn?.AddOn?.id)
    //     );
    //     handleAdd(bookingServiceDetails, selectedAddOnsDetails);
    //     // onClose();
    // };
    // const handleAddOnChange = (addOnId) => {
    //     setSelectedAddOns((prev) =>
    //         prev.includes(addOnId)
    //             ? prev.filter((id) => id !== addOnId)
    //             : [...prev, addOnId]
    //     );
    //     handleAdd1()
    // };


    return (
        <div className="container mx-auto min-h-screen bg-gray-100 mt-4 space-y-6 book_service">
            {/* Breadcrumb Navigation */}
            <div className="w-full breadcrump" >
                <nav className="text-sm font-medium text-gray-500">
                    <ol className="list-reset flex gap-3 items-center">
                        <li>
                            <a href="#" className="first_link underline">
                                Services
                            </a>
                        </li>
                        <li>
                            <img src={RightArrow} alt="" />
                        </li>
                        <li>
                            <a href="#" className="second_link text-green-600 hover:text-green-800">
                                Pick your Location
                            </a>
                        </li>
                    </ol>
                </nav>
            </div >

            <ProgressBar step1={true} step2={false} step3={false} />

            {/* Header Section */}
            <div className="w-full service_heading">
                <h2 className="header">
                    <span className="first_head">{bookingServiceDetails?.name?.split("-")[0]?.trim()}{bookingServiceDetails?.name?.split("-")[1] ? ": " : ""}</span><span className="second_head">{bookingServiceDetails?.name?.split("-")[1]}</span>
                </h2>
            </div>

            {errorMessage && <Error errorMessage={errorMessage} />}

            {/* Add Booking Details Section */}
            <section className="w-full bg-white rounded-lg  p-8 add_booking">
                <span className="first_head">Add Booking </span><span className="second_head">Details</span>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 add_booking_fields">
                    <div className="relative">
                        <input
                            type="date"
                            defaultValue={bookingValues?.date || ""}
                            placeholder=" "
                            className={`input_field mt-1 block w-full ${formErrors?.date
                                ? `form_error_border`
                                : `border-gray-200`
                                }`}
                            name="date"
                            onChange={(e) => {
                                onHandleChangeBooking(e);
                            }}
                        />
                        <span className="input_placeholder">Working Day</span>
                        <FormError error={formErrors.date} />
                    </div>
                    <div className="relative">
                        <input
                            type="time"
                            defaultValue={bookingValues?.start_time || ""}
                            placeholder=" "
                            className={`input_field mt-1 block w-full ${formErrors?.start_time
                                ? `form_error_border`
                                : `border-gray-200`
                                }`}
                            name="start_time"
                            onChange={(e) => {
                                onHandleChangeBooking(e);
                            }}
                        />
                        <span className="input_placeholder">Start Time</span>
                        <FormError error={formErrors.start_time} />
                    </div>
                    <div className="relative w-full" ref={dropdownRef}>
                        <input
                            type="text"
                            className={`input_field mt-1 block w-full select_quantity ${formErrors?.service_type_id
                                ? `form_error_border`
                                : `border-gray-200`
                                }`}
                            placeholder=" "
                            value={selectedOption}
                            onFocus={handleFocus}
                        // onChange={(e) => setSelectedOption(e.target.value)}
                        />
                        <span className="input_placeholder">Service Type</span>
                        <FormError error={formErrors.service_type_id} />
                        {isDropdownVisible && (
                            <div className="absolute left-0 right-0 z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                                <ul className="py-2">
                                    {serviceTypes && serviceTypes?.map((value, i) => {
                                        return <li
                                            key={i}
                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => handleOptionClick(value)}
                                        >
                                            {value?.value}{" - "}{"Rs " + value?.price}
                                        </li>
                                    })
                                    }
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 mt-4 add_booking_fields">
                    <div className="relative">
                        <input
                            type="text"
                            defaultValue={bookingValues?.note || ""}
                            placeholder=" "
                            className={`input_field mt-1 block w-full ${formErrors?.note
                                ? `form_error_border`
                                : `border-gray-200`
                                }`}
                            name="note"
                            onChange={(e) => {
                                onHandleChangeBooking(e);
                            }}
                        />
                        <span className="input_placeholder ">Anything for us to NOTE</span>
                        <FormError error={formErrors.note} />
                    </div>
                </div>

                {/* <Slider {...settings}>
                    {addOnList?.map((addOn) => {
                        return <div key={addOn.id} className="flex flex-col items-center justify-between mb-2">
                            <img src={`${base_url}${addOn?.AddOn?.image}`} alt="" width="120" height="120" />
                            <span>{addOn?.AddOn?.name}</span>
                            <div className="flex items-center">
                                <span className="mr-2 text-sm text-gray-600">₹{addOn?.AddOn?.price}</span>
                                <input
                                    type="checkbox"
                                    checked={selectedAddOns.includes(addOn?.AddOn?.id)}
                                    onChange={() => handleAddOnChange(addOn?.AddOn?.id)}
                                    className="form-checkbox h-5 w-5 text-indigo-600"
                                />
                            </div>
                        </div>
                    })}
                </Slider> */}

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                    {cart[cart?.length - 1]?.addOns?.map((value) => {
                        return <div className="flex gap-4">
                            <div><span className="text_first">{"Service name: "}</span><span className="text_second">{value?.AddOn?.name}</span></div>
                            <div><span className="text_first">{"Price: "}</span><span className="text_second">{"Rs "}{value?.AddOn?.price}</span></div>
                        </div>
                    })}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4 add_booking_fields">
                    <button className="btn_secondary" onClick={() => { setAddonModalOpen(!addOnModalOpen) }}>Service Add On</button>
                </div>
            </section>

            {/* Pick your Location Section */}

            <section className="w-full bg-white rounded-lg p-8 pick_location">
                <div className="flex justify-between">
                    <div>
                        <span className="first_head">Choose </span><span className="second_head">Location</span>
                    </div>
                    <div className="cursor-pointer" onClick={() => { navigate(`/${routePath.CustomerLayoutPath}/${routePath.addressPath}`, { state: { serviceId: id } }); dispatch(setBookingDetails(bookingValues));; dispatch(setSelectedServiceType(selectedOption)); dispatch(setSelectedAddOn(cart)) }}>
                        <span className="second_head font-sm">Add Address</span>
                    </div>
                </div>
                <div className="mt-4 pick_location_body">
                    {/* <img src={MapImage} alt="Map" className="w-full rounded-lg shadow-sm" /> */}
                    <div className="w-full rounded-lg shadow-sm h-96">
                        <Map
                            style={{ borderRadius: "20px" }}
                            defaultZoom={13}
                            defaultCenter={markerLocation}
                            gestureHandling={"greedy"}
                            disableDefaultUI
                        >
                            <Marker position={markerLocation} />
                        </Map>

                        {/* <Maps markerLocation={markerLocation} /> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 pick_location_fields">
                        <div className="relative">
                            <input
                                type="text"
                                defaultValue={bookingValues?.address ? bookingValues?.address : ""}
                                placeholder=" "
                                className={`input_field mt-1 block w-full ${formErrors?.address
                                    ? `form_error_border`
                                    : `border-gray-200`
                                    }`}
                                name="address"
                                onChange={(e) => {
                                    onHandleChangeBooking(e);
                                }}
                            />
                            <span className="input_placeholder ">Apartment/Road/Area</span>
                            <FormError error={formErrors.address} />
                        </div>
                        <div className="relative">
                            <input
                                type="text"
                                defaultValue={bookingValues?.flat_number ? bookingValues?.flat_number : ""}
                                placeholder=" "
                                className={`input_field mt-1 block w-full ${formErrors?.flat_number
                                    ? `form_error_border`
                                    : `border-gray-200`
                                    }`}
                                name="flat_number"
                                onChange={(e) => {
                                    onHandleChangeBooking(e);
                                }}
                            />
                            <span className="input_placeholder ">House/Flat No</span>
                            <FormError error={formErrors.flat_number} />
                        </div>
                        <div className="relative">
                            <input
                                type="text"
                                defaultValue={bookingValues?.state ? bookingValues?.state : ""}
                                placeholder=" "
                                className={`input_field mt-1 block w-full ${formErrors?.state
                                    ? `form_error_border`
                                    : `border-gray-200`
                                    }`}
                                name="state"
                                onChange={(e) => {
                                    onHandleChangeBooking(e);
                                }}
                            />
                            <span className="input_placeholder ">Location{"'"}s Name</span>
                            <FormError error={formErrors.state} />
                        </div>

                        <div className="relative w-full" ref={dropdownRefAddressType}>
                            <input
                                type="text"
                                className={`input_field mt-1 block w-full select_quantity ${formErrors?.type
                                    ? `form_error_border`
                                    : `border-gray-200`
                                    }`}
                                placeholder=" "
                                value={addressTypeOption}
                                onFocus={handleAddressTypeSelect}
                            />
                            <span className="input_placeholder ">Address Type</span>
                            <FormError error={formErrors.type} />
                            {isAddressTypeVisible && (
                                <div className="absolute left-0 right-0 z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                                    <ul className="py-2">
                                        <li
                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => handleAddressTypeClick("Home")}
                                        >
                                            {"Home"}
                                        </li>
                                        <li
                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => handleAddressTypeClick("Work")}
                                        >
                                            {"Work"}
                                        </li>
                                        <li
                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => handleAddressTypeClick("Friends and family")}
                                        >
                                            {"Friends and family"}
                                        </li>
                                        <li
                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => handleAddressTypeClick("Other")}
                                        >
                                            {"Other"}
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {/* error */}
            {/* {errorMessage && <Error errorMessage={errorMessage} />} */}

            {/* Action Buttons */}
            <div className="w-full flex gap-3 py-6 justify-end service_buttons">
                <button className="bg-gray-200 text-gray-700 py-2 px-4 rounded-lg cancel" onClick={() => { navigate(`/${routePath.CustomerLayoutPath}/${routePath.servicesListPath}`); dispatch(setBookAgainAddress(null)) }}>Cancel</button>
                <button className="bg-green-600 text-white py-2 px-4 rounded-lg next" onClick={(e) => { handleSubmit(e) }}>Next</button>
            </div>
            <ServiceOffCanvas isOpen={isOpen} setIsOpen={setIsOpen} onClickFn={handlePaymentSelect} />

            <ConfirmBookingOffcanvas
                isOpenBookingConfirmation={isOpenBookingConfirmation}
                isExpandedOnViewMore={isExpandedOnViewMore}
                setIsOpenBookingConfirmation={setIsOpenBookingConfirmation}
                setIsExpandedOnViewMore={setIsExpandedOnViewMore}
                onClickFn={displayRazorpay}
                serviceTypePrice={serviceTypePrice}
                serviceBasePrice={bookingServiceDetails?.price}
                description={bookingServiceDetails?.description}
                setCouponCode={setCouponCode}
                checkCoupon={getCouponDetails}
                couponCheckMessage={couponCheckMessage}
                couponCode={couponCode}
            />

            <BookingConfirmModal isOpen={isModalOpen} onClose={handleClose} />

            <AddOnModal
                service={bookingServiceDetails}
                isOpen={addOnModalOpen}
                onClose={() => setAddonModalOpen(false)}
                onAdd={handleAdd}
                selectedAddOns={selectedAddOns}
                setSelectedAddOns={setSelectedAddOns}
                addOns={addOnList}
                serviceTypePrice={serviceTypePrice}
            />
        </div >
    );
};

export default BookService;
